import React from "react"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { store } from 'react-notifications-component'

const Social = [
  {
    title: "Instagram",
    link: "https://instagram.com",
    image: "/images/social/instagram.svg",
  },
  {
    title: "Twitter",
    link: "https://twitter.com",
    image: "/images/social/twitter.svg",
  },
  {
    title: "Facebook",
    link: "https://fb.com",
    image: "/images/social/facebook.svg",
  },
  {
    title: "Linkedin",
    link: "https://linkedin.com",
    image: "/images/social/linkedin.svg",
  },
]

function submitForm(ev) {
  ev.preventDefault();
  const form = ev.target;
  const data = new FormData(form);
  const xhr = new XMLHttpRequest();
  xhr.open(form.method, form.action);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== XMLHttpRequest.DONE) return;
    if (xhr.status === 200) {
      form.reset();
      store.addNotification({
        title: 'Success',
        message: 'Thanks for contacting us :)',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "faeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      })
    } else {
      store.addNotification({
        title: 'Oops',
        message: 'There was an error :(',
        type: 'error',
        insert: 'top',
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "faeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      })
    }
  };
  xhr.send(data);
}

const Contact = () => {
  return (
    <div>
      <form
        name="contact"
        onSubmit={submitForm}
        action="https://formspree.io/moqkygaa"
        method="POST"
      >

        <div className="field is-horizontal">

          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              required
              placeholder="First Name"
            />
          </div>

          <div className="is-vertical">&nbsp;</div>

          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              placeholder="Last Name"
            />
          </div>

        </div>

        <div className="field">
          <div className="control">
            <input
              className="input"
              type={"email"}
              name="email"
              id={"email"}
              required
              placeholder="Your Email"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              name={"message"}
              id={"message"}
              required
              placeholder="Message"
            />
          </div>
        </div>

        <div className="field">
          <button className="button is-link" type="submit">
            Send
          </button>
        </div>

      </form>
    </div>
  )
}

const Footer = () => (
  <footer className="footer">
    <div className="container content">
      <div className="columns is-mobile is-multiline is-gapless">

        <div className="column is-4-tablet is-12-mobile">
          <section className="menu">
            <ul className="menu-list">
              <li>
                <div className="navbar-content list-header">
                  About Us
                </div>
              </li>
              <li className="navbar-item">
                <p>
                  The Fastest way to Increase your Fanbase on Beatstars
                  <br />
                  Reach out to your target audience and connet with them in a fast and automatic way. You focus on creating
                  awesome beats and let Beatshine do the rest for you
                </p>
              </li>
            </ul>
          </section>
        </div>

        <div className="column is-4-tablet is-6-mobile">
          <section className="menu">
            <ul className="menu-list">
              <li className="list-header is-padded-bottom">
                Contact Us
              </li>
              <li>
                {Contact()}
              </li>
            </ul>
          </section>
        </div>
      </div>

      <div
        className="has-text-weight-semibold has-text-centered"
        style={{
          padding: "2% 0 3%"
        }}
      >
        <p className="horizantal-line" />
        <div className="is-size-5-tablet is-size-7-mobile text-roboto">
          <div>
            By installing the extension you agree to our&nbsp;&nbsp;
            <span 
              onClick={() => { window.open("https://privacy.beatshine.com", "_blank") }}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}>Terms &amp; Conditions</span>
          </div>
          <div>
            Not in an assosiation with Beatstars. Reach out to us at support@beatshine.com
          </div>
          <div>
            © {new Date().getFullYear()} Beatshine
          </div>
        </div>
      </div>
    </div>
  </footer>
)

const ProfilemateFooter = () => (
  <footer className="footer">
    <div className="container content">
      {/*<div className="columns is-mobile is-multiline is-gapless">

        <div className="column is-4-tablet is-12-mobile">
          <section className="menu">
            <ul className="menu-list">
              <li>
                <div className="navbar-content list-header">
                  About Us
                </div>
              </li>
              <li className="navbar-item">
                <p>
                  The Fastest way to Increase your Fanbase on Beatstars
                  <br />
                  Reach out to your target audience and connet with them in a fast and automatic way. You focus on creating
                  awesome beats and let Beatshine do the rest for you
                </p>
              </li>
            </ul>
          </section>
        </div>

        <div className="column is-4-tablet is-6-mobile">
          <section className="menu">
            <ul className="menu-list">
              <li className="list-header is-padded-bottom">
                Contact Us
              </li>
              <li>
                {Contact()}
              </li>
            </ul>
          </section>
        </div>
      </div>*/}

      <div
        className="has-text-weight-semibold has-text-centered"
        style={{
          padding: "2% 0 3%"
        }}
      >
        <p className="horizantal-line" />
        <div className="is-size-5-tablet is-size-7-mobile text-roboto">
          <div>
            By installing the extension you agree to our&nbsp;&nbsp;
            <span 
              onClick={() => { window.open("https://vipmemberprivacy.profilebud.com", "_blank") }}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}>Terms &amp; Conditions</span>
          </div>
          <div>
            © {new Date().getFullYear()} VIP MEMBER
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default ProfilemateFooter
